function DancingButton({ onClick, children, className = "" }) {
    return (
      <button
        onClick={onClick}
        className={`
          relative overflow-hidden rounded-full
          bg-gradient-to-r from-periwinkle-300 via-periwinkle-400 to-periwinkle-300
          bg-[length:400%_400%]
          animate-gradient-dance
          text-white px-8 py-3 
          font-sans tracking-wide 
          transition-all duration-300
          hover:animate-gradient-dance-fast
          hover:scale-[1.02]
          hover:shadow-[0_0_20px_rgba(128,159,255,0.3)]
          active:scale-95
          ${className}
        `}
      >
        <span className="relative z-10 animate-pulse-subtle">
          {children}
        </span>
      </button>
    );
  }
  
  export default DancingButton;