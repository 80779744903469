import { useState, useEffect } from 'react';
import { messageService } from '../../firebase/messageService';

function EmojiLeaderboard() {
  const [emojiCounts, setEmojiCounts] = useState([]);

  useEffect(() => {
    async function fetchEmojiCounts() {
      try {
        const counts = await messageService.getEmojiCounts();
        setEmojiCounts(counts);
      } catch (error) {
        console.error('Error fetching emoji counts:', error);
      }
    }

    fetchEmojiCounts();
  }, []);

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl text-slate-950 font-sans tracking-wide mb-8">
        Emoji Leaderboard 🏆
      </h1>
      <div className="space-y-4">
        {emojiCounts.map(({ emoji, count }, index) => (
          <div 
            key={emoji}
            className="flex items-center justify-between p-4 bg-white/80 rounded-lg shadow-sm"
          >
            <div className="flex items-center gap-4">
              <span className="text-2xl font-bold text-slate-600">#{index + 1}</span>
              <span className="text-4xl">{emoji}</span>
            </div>
            <span className="text-xl text-slate-600">× {count}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmojiLeaderboard;