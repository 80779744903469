function About() {
    return (
      <div className="p-10 flex items-center justify-center">
        <div className="max-w-3xl mx-auto">
          <div className="bg-white rounded-lg shadow-lg p-8 md:p-12">
            <div className="w-48 h-48 md:w-64 md:h-64 mx-auto mb-8 rounded-full overflow-hidden">
              <img
                src="./Images/snoozin.jpeg"
                alt="Photo of K"
                className="w-full h-full object-cover"
              />
            </div>
            
            {/* Bio text */}
            <div className="space-y-6 text-left">
              <h1 className="text-2xl md:text-3xl text-periwinkle-400 font-sans font-medium">
                Thanks for wishing Karuna a happy 30th birthday!
              </h1>
              
              <div className="space-y-4 text-lg md:text-xl text-gray-700 font-sans leading-relaxed">
                <p>
                  I'm Pete, K's lesser half!
                </p>

                <p>
                  I created this smöl website to gather words of affirmation and wisdom from her friends, bringing a smile to her face on her birthday and every day after.
                </p>
                
                <p>
                  If you have any questions, feel free to reach out. Thanks for helping make her birthday unforgettable!
                </p>
              </div>
            </div>
            
            {/* Leave Message button */}
            <div className="mt-12 text-center">
              <a
                href="/"
                className="inline-block px-8 py-4 bg-periwinkle-400 text-white text-lg md:text-xl rounded-full font-sans tracking-wide hover:bg-periwinkle-500 transition-colors"
              >
                Leave a Message
              </a>
            </div>
          </div>
        </div>
      </div>

    );
  }
  
  export default About;