import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBcOU5PZrxiS8cJ7daxNuf_8QneRMKe7q4",
  authDomain: "hbd-k-40dc4.firebaseapp.com",
  projectId: "hbd-k-40dc4",
  storageBucket: "hbd-k-40dc4.firebasestorage.app",
  messagingSenderId: "117352639653",
  appId: "1:117352639653:web:ecac2ac9dd2873d624b14c",
  measurementId: "G-RW9GDPG55N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Storage
const storage = getStorage(app);

export { db, storage };