import { useState } from 'react';

function ForK() {
    const [showNote, setShowNote] = useState(false);

    return (
        <div className="min-h-screen bg-cream p-8">
            <div className="max-w-3xl mx-auto">
                <div className="bg-white rounded-lg shadow-lg p-8 md:p-12">
                    {/* Header */}
                    <h1 className="text-3xl md:text-4xl text-periwinkle-400 font-sans font-medium mb-6">
                        Happy Birthday, K! 🎉
                    </h1>

                    {/* Navigation Cards - Ultra slim version */}
                    <div className="grid grid-cols-1 gap-3">
                        {/* Note from Pete Card */}
                        <button
                            onClick={() => setShowNote(true)}
                            className="block w-full px-4 py-3 bg-rose-50 rounded-lg hover:bg-rose-100 transition-colors"
                        >
                            <h2 className="text-lg text-rose-400 font-medium text-center">
                                💝 Note from Pete
                            </h2>
                        </button>

                        {/* Messages Card */}
                        <a
                            href="/messages"
                            className="block px-4 py-3 bg-periwinkle-50 rounded-lg hover:bg-periwinkle-100 transition-colors"
                        >
                            <h2 className="text-lg text-periwinkle-400 font-medium text-center">
                                💌 Read Everyone's Messages
                            </h2>
                        </a>

                        {/* Emoji Leaderboard Card */}
                        <a
                            href="/leaderboard"
                            className="block px-4 py-3 bg-periwinkle-50 rounded-lg hover:bg-periwinkle-100 transition-colors"
                        >
                            <h2 className="text-lg text-periwinkle-400 font-medium text-center">
                                🏆 Emoji Leaderboard
                            </h2>
                        </a>

                        {/* About Card */}
                        <a
                            href="/about"
                            className="block px-4 py-3 bg-periwinkle-50 rounded-lg hover:bg-periwinkle-100 transition-colors"
                        >
                            <h2 className="text-lg text-periwinkle-400 font-medium text-center">
                                🌻 About Site
                            </h2>
                        </a>
                    </div>
                </div>
            </div>

            {/* Modal/Popup for Pete's Note */}
            {showNote && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
                    onClick={() => setShowNote(false)}
                >
                    <div
                        className="bg-white rounded-lg shadow-xl max-w-3xl max-h-[90vh] w-full overflow-y-auto"
                        onClick={e => e.stopPropagation()}
                    >
                        <div className="p-6 md:p-8">
                            <div className="flex justify-between items-center mb-6">
                                <h2 className="text-2xl md:text-3xl text-rose-400 font-medium">
                                    Note from Pete 💝
                                </h2>
                                <button
                                    onClick={() => setShowNote(false)}
                                    className="text-gray-400 hover:text-gray-600"
                                >
                                    ✕
                                </button>
                            </div>

                            {/* Personal Note */}
                            <div className="prose prose-lg max-w-none">
                                <p className="text-gray-700 text-lg md:text-xl leading-relaxed">
                                    <span className="font-bold text-rose-400">Hey Karunas!</span>
                                    <br />
                                    <br />
                                    Happy Birthday! I'm so excited to finally give you this gift that me (and many other people) have been contributing to. It's a collection of wishes and memories from the people you love most -- accessible on your phone whenever you need it.
                                    <br />
                                    <br />
                                    In-short, I wanted to make you a gift that would capture some of the love and memories from your friends all over the world. There are so many people who love you and I knew the best gift I could make is finding a way to bring them together on this lil site.
                                    <br />
                                    <br />
                                    In-order to make this happen, I built the site in November and had sent out the link to all of your friends with with the Partiful -- that's what I was hiding from you!! I also recruited a few people like Rachel, Lizzie, your parents, and more to help spread the world as well.
                                    <br />
                                    <br />
                                    I didn't get everyone you love to leave a note (I sent a ton of reminders and a few <span className="font-bold italic">very</span> direct texts), but I'm proud to say this site made it to many of your friends and family. This site even has a few users in Japan! Overall, I hope you can see how many people love you and how many memories you've made with them over the years.
                                    <br />
                                    <br />
                                    I hope you enjoy it! I think out of any gift I could have given or made for you, this was one I felt like was the best. There are also some earrings that I ordered a little late and got backed up on delievry lol but they are kinda just the physical part of it.
                                    <br />
                                    <br />
                                    I love you and I hope you have a great birthday! I hope you can see how much you mean to so many people, and how there was no way people were going to miss your birthday at Farm.One you fuggin ball of stress.
                                    <br />
                                    <br />
                                    I'm forever proud of you and I'm so excited to see what 30 brings you, after all I'll be right there with you.
                                    <br />
                                    <br />
                                </p>
                                <br />
                                <p className="text-periwinkle-400 text-lg md:text-xl leading-relaxed">
                                    Love, Petey
                                </p>
                                <br />
                                <p className="text-gray-700 text-lg md:text-xl leading-relaxed">
                                    P.S. Sorry I roasted your crown but between building this, sending it out, and planning your birthday it feels like your birthday has been going on for a month+ LOL. 
                                    <br />
                                    Sorry for being a grinch sometime :/ but thank you for always being a ray of sunshine 🌻
                                </p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ForK;