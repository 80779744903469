import { collection, addDoc, getDocs, query, where, orderBy, serverTimestamp } from 'firebase/firestore';
import { db, storage } from './config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const COLLECTION_NAME = 'messages';

export const messageService = {
  // Add a new message
  async addMessage(messageData) {
    try {
      // Validate required fields
      if (!messageData.name || !messageData.emoji || !messageData.memory || !messageData.wish) {
        throw new Error('Missing required fields');
      }

      // Clean and prepare the data
      const cleanedData = {
        name: messageData.name.trim(),
        emoji: messageData.emoji.trim(),
        memory: messageData.memory.trim(),
        wish: messageData.wish.trim(),
        timestamp: serverTimestamp(), // Using serverTimestamp for consistency
        createdAt: new Date().toISOString()
      };

      // If there's a photo, upload it to Firebase Storage
      if (messageData.photo) {
        const photoRef = ref(storage, `photos/${messageData.photo.name}`);
        await uploadBytes(photoRef, messageData.photo); // Upload the file

        // Get the download URL
        const photoURL = await getDownloadURL(photoRef);

        cleanedData.photo = messageData.photo; // Add the photo URL to the message data
      }
      
      // // Log the cleaned data to verify the photo URL
      // console.log('Message Data:', messageData);
      // console.log('Photo URL:', cleanedData.photo);
      // console.log('Cleaned Data:', cleanedData);

      const docRef = await addDoc(collection(db, COLLECTION_NAME), cleanedData);
      console.log('Document ID:', docRef.id);
      return docRef.id;
    } catch (error) {
      console.error('Error adding message:', error);
      throw new Error(error.message || 'Failed to save message');
    }
  },

  // Get all messages
  async getMessages() {
    try {
      const q = query(
        collection(db, COLLECTION_NAME),
        orderBy('timestamp', 'desc')
      );
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Convert timestamp to JS Date if it exists
        timestamp: doc.data().timestamp?.toDate() || null
      }));
    } catch (error) {
      console.error('Error getting messages:', error);
      throw new Error('Failed to fetch messages');
    }
  },

  // Search messages by name
  async searchMessagesByName(name) {
    try {
      if (!name || typeof name !== 'string') {
        throw new Error('Invalid search term');
      }

      const searchTerm = name.trim().toLowerCase();
      const q = query(
        collection(db, COLLECTION_NAME),
        where('name', '>=', searchTerm),
        where('name', '<=', searchTerm + '\uf8ff'),
        orderBy('name'),
        orderBy('timestamp', 'desc')
      );
      
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        // Convert timestamp to JS Date if it exists
        timestamp: doc.data().timestamp?.toDate() || null
      }));
    } catch (error) {
      console.error('Error searching messages:', error);
      throw new Error('Failed to search messages');
    }
  },

  // Helper method to format dates consistently
  formatDate(timestamp) {
    if (!timestamp) return '';
    try {
      return new Date(timestamp).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      return '';
    }
  },

  // Get emoji counts for leaderboard
  async getEmojiCounts() {
    try {
      const messages = await this.getMessages();
      
      // Combine all emojis into one string and remove spaces
      const allEmojis = messages
        .map(message => (message.emoji || '').replace(/\s/g, ''))
        .join('');
      
      console.log('All emojis combined:', allEmojis);
      
      // Use Intl.Segmenter to properly split emoji sequences
      const segmenter = new Intl.Segmenter('en', { granularity: 'grapheme' });
      const segments = [...segmenter.segment(allEmojis)];
      
      // Count unique emoji sequences
      const counts = {};
      segments.forEach(({segment}) => {
        if (segment.trim()) {  // Only count non-whitespace characters
          counts[segment] = (counts[segment] || 0) + 1;
        }
      });
      
      // Convert to array and sort by count
      const sortedCounts = Object.entries(counts)
        .map(([emoji, count]) => ({ emoji, count }))
        .sort((a, b) => b.count - a.count)
        .filter(({emoji}) => emoji.trim() && emoji !== '️'); // Filter out empty entries and variation selectors
      
      console.log('Emoji counts:', sortedCounts);
      return sortedCounts;
    } catch (error) {
      console.error('Error getting emoji counts:', error);
      throw new Error('Failed to fetch emoji counts');
    }
  }
};