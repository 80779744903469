import { useState, useEffect, useRef } from 'react';
import DancingButton from '../DancingButton';
import { storage } from '../../firebase/config'; // Import storage
import { messageService } from '../../firebase/messageService';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Import storage functions

function MessageForm() {
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loadingStep, setLoadingStep] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    emoji: '',
    memory: '',
    wish: '',
    photo: null
  });
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [uploadedFileName, setUploadedFileName] = useState(''); // State for uploaded file name
  
  const loadingMessages = [
    "Reading your response...",
    "Nice emoji choice!",
    "Aww what a sweet message",
    "Almost there..."
  ];

  const questions = {
    1: {
      label: "What's your name?",
      placeholder: "Enter your name",
      field: "name",
      type: "text"
    },
    2: {
      label: "One emoji that reminds you of K",
      placeholder: "Choose an emoji",
      field: "emoji",
      type: "text"
    },
    3: {
      label: "One memory about Karuna is...",
      placeholder: "Share something heartfelt or funny",
      field: "memory",
      type: "textarea"
    },
    4: {
      label: "Your wish for K's 30th year",
      placeholder: "Share your wish for K",
      field: "wish",
      type: "textarea"
    },
    5: {
      label: "Upload a photo for K",
      placeholder: "Choose a photo",
      field: "photo",
      type: "file"
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        return value.trim().length >= 2;
      case 'emoji':
        const emojiLength = Array.from(value.trim()).length;
        return emojiLength >= 1 && emojiLength <= 4;
      case 'memory':
      case 'wish':
        return value.trim().length >= 10;
      default:
        return true;
    }
  };

  const handleInputChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const nextStep = () => {
    const currentField = questions[step].field;
    if (!validateField(currentField, formData[currentField])) {
      alert('Please provide a valid response before continuing.');
      return;
    }
    
    if (step < 5) {
      setStep(step + 1);
    } else {
      setShowReview(true);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    try {
      // Validate all fields one last time
      const isValid = Object.entries(formData).every(([field, value]) => 
        validateField(field, value)
      );

      if (!isValid) {
        throw new Error('Please fill out all fields correctly');
      }

      // Upload photo to Firebase Storage
      let photoURL = null;
      if (formData.photo) {
        const photoRef = ref(storage, `photos/${formData.photo.name}`);
        await uploadBytes(photoRef, formData.photo);
        photoURL = await getDownloadURL(photoRef); // Get the download URL
      }

      // Clean the data before sending
      const messageData = {
        name: formData.name.trim(),
        emoji: formData.emoji.trim(),
        memory: formData.memory.trim(),
        wish: formData.wish.trim(),
        photo: photoURL // Include the photo URL
      };

      console.log('Message Data:', messageData); // Log the message data

      await messageService.addMessage(messageData);
      setIsSubmitting(false);
      setShowSuccess(true);
    } catch (error) {
      console.error('Error saving message:', error);
      alert(error.message || 'There was an error saving your message. Please try again.');
      setIsSubmitting(false);
    }
  };

  const getFieldHelper = (field) => {
    switch (field) {
      case 'name':
        return 'So she knows who you are!';
      case 'emoji':
        return 'Choose one emoji';
      case 'memory':
        return 'At least 10 characters but not a novel plz.';
      case 'wish':
        return 'At least 10 characters but, again, not too long otherwise the UI will break.';
      default:
        return '';
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && currentQuestion.type !== 'textarea') {
      e.preventDefault();
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        photo: file // Store the file object
      }));
      setUploadedFileName(file.name); // Set the uploaded file name
    }
  };

  // Timer ref to clear timeout on unmount
  const loadingTimerRef = useRef(null);

  useEffect(() => {
    if (isSubmitting) {
      // Reset loading step
      setLoadingStep(0);

      // Function to handle loading messages
      const showLoadingMessages = () => {
        loadingTimerRef.current = setTimeout(() => {
          setLoadingStep((prev) => {
            if (prev < loadingMessages.length - 1) {
              return prev + 1; // Move to the next message
            } else {
              return prev; // Stay on the last message
            }
          });
        }, 2000); // Change duration here (2000 ms = 2 seconds)
      };

      // Start showing loading messages
      showLoadingMessages();

      // Cleanup function to clear the timer
      return () => clearTimeout(loadingTimerRef.current);
    }
  }, [isSubmitting]);

  if (isSubmitting) {
    return (
      <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8 md:p-12 text-center">
        <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-periwinkle-400 mx-auto mb-8"></div>
        <p className="text-periwinkle-400 font-sans text-xl md:text-2xl animate-fade-in">
          {loadingMessages[loadingStep]}
        </p>
      </div>
    );
  }

  if (showSuccess) {
    return (
      <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8 md:p-12 text-center">
        <div className="mb-8">
          <div className="w-20 h-20 bg-periwinkle-100 rounded-full flex items-center justify-center mx-auto mb-6">
            <span className="text-3xl">✨</span>
          </div>
          <h3 className="text-2xl md:text-3xl font-sans text-periwinkle-400 mb-6">
            Thanks for your message, Karuna is going to love it!
          </h3>
          <p className="text-gray-600 text-lg md:text-xl mb-8">
            Want to see which emoji is winning? Check out the leaderboard!
          </p>
          <a
            href="/leaderboard"
            className="text-periwinkle-400 hover:text-periwinkle-500 underline text-xl"
          >
            View Emoji Leaderboard 🏆
          </a>
        </div>
        <button
          onClick={() => {
            setFormData({ name: '', emoji: '', memory: '', wish: '', photo: null });
            setStep(1);
            setShowSuccess(false);
          }}
          className="text-periwinkle-300 hover:text-periwinkle-400 text-sm"
        >
          Submit another message
        </button>
      </div>
    );
  }

  if (showReview) {
    return (
      <div className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-12 md:p-16">
        <h3 className="text-2xl md:text-3xl font-sans text-periwinkle-400 mb-8">Review Your Message</h3>
        <div className="space-y-6 mb-8">
          <div>
            <p className="text-base md:text-lg text-gray-500">Your Name</p>
            <p className="text-xl md:text-2xl text-periwinkle-400">{formData.name}</p>
          </div>
          <div>
            <p className="text-base md:text-lg text-gray-500">Your Emoji for K</p>
            <p className="text-3xl md:text-4xl">{formData.emoji}</p>
          </div>
          <div>
            <p className="text-base md:text-lg text-gray-500">About K</p>
            <p className="text-xl md:text-2xl text-periwinkle-400">{formData.memory}</p>
          </div>
          <div>
            <p className="text-base md:text-lg text-gray-500">Your Wish</p>
            <p className="text-xl md:text-2xl text-periwinkle-400">{formData.wish}</p>
          </div>
          {formData.photo && (
            <div>
              <p className="text-base md:text-lg text-gray-500">Your Photo</p>
              <img src={URL.createObjectURL(formData.photo)} alt="Uploaded" className="w-full h-auto rounded-lg" />
            </div>
          )}
        </div>
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={() => setShowReview(false)}
            className="flex-1 py-4 text-lg md:text-xl border border-periwinkle-400 text-periwinkle-400 rounded-full font-sans tracking-wide hover:bg-periwinkle-50 transition-colors"
          >
            Edit
          </button>
          <DancingButton onClick={handleSubmit} className="flex-1">
            Send Message
          </DancingButton>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[step];

  return (
    <form onSubmit={(e) => e.preventDefault()} className="w-full max-w-2xl mx-auto bg-white rounded-lg shadow-lg p-8 md:p-12">
      <div className="mb-8">
        <div className="flex justify-between mb-4">
          <span className="text-base md:text-lg text-periwinkle-300">Question {step} of 5</span>
          <span className="text-base md:text-lg text-periwinkle-300">{Math.round((step / 5) * 100)}%</span>
        </div>
        <div className="w-full bg-periwinkle-100 rounded-full h-3">
          <div
            className="bg-periwinkle-400 h-3 rounded-full transition-all duration-300"
            style={{ width: `${(step / 5) * 100}%` }}
          ></div>
        </div>
      </div>

      <div className="space-y-8">
        <div>
          <label className="block font-sans text-xl md:text-2xl text-periwinkle-400 mb-4 tracking-wide">
            {currentQuestion.label}
          </label>
          {currentQuestion.type === 'file' ? (
            <>
              <div className="mb-4">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden" // Hide the default file input
                  ref={fileInputRef} // Attach the ref to the file input
                />
                <button
                  type="button"
                  onClick={handleFileButtonClick} // Trigger the file input click
                  className="w-full px-6 py-4 text-lg md:text-xl border border-periwinkle-200 rounded-lg bg-periwinkle-400 text-white hover:bg-periwinkle-500 focus:outline-none focus:ring-2 focus:ring-periwinkle-200 transition-colors"
                >
                  Upload a Photo
                </button>
                <p className="mt-2 text-base md:text-lg text-gray-500">
                  {getFieldHelper(currentQuestion.field)}
                </p>
                {uploadedFileName && ( // Show the uploaded file name if it exists
                  <p className="mt-2 text-base md:text-lg text-periwinkle-400">
                    Uploaded: {uploadedFileName}
                  </p>
                )}
              </div>
            </>
          ) : (
            <>
              {currentQuestion.type === 'textarea' ? (
                <>
                  <textarea
                    value={formData[currentQuestion.field]}
                    onChange={(e) => handleInputChange(currentQuestion.field, e.target.value)}
                    placeholder={currentQuestion.placeholder}
                    rows={5}
                    className="w-full px-6 py-4 text-lg md:text-xl border border-periwinkle-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-periwinkle-200 font-sans"
                  />
                  <p className="mt-2 text-base md:text-lg text-gray-500">
                    {getFieldHelper(currentQuestion.field)}
                  </p>
                </>
              ) : (
                <>
                  <input
                    type={currentQuestion.type}
                    value={formData[currentQuestion.field]}
                    onChange={(e) => handleInputChange(currentQuestion.field, e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder={currentQuestion.placeholder}
                    className="w-full px-6 py-4 text-lg md:text-xl border border-periwinkle-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-periwinkle-200 font-sans"
                  />
                  <p className="mt-2 text-base md:text-lg text-gray-500">
                    {getFieldHelper(currentQuestion.field)}
                  </p>
                </>
              )}
            </>
          )}
        </div>

        <div className="flex space-x-4">
          {step > 1 && (
            <button
              type="button"
              onClick={prevStep}
              className="flex-1 py-4 text-lg md:text-xl border border-periwinkle-400 text-periwinkle-400 rounded-full font-sans tracking-wide hover:bg-periwinkle-50 transition-colors"
            >
              Back
            </button>
          )}
          <DancingButton
            onClick={nextStep}
            className="flex-1 py-4 text-lg md:text-xl disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={!formData[currentQuestion.field]}
          >
            {step === 5 ? 'Review' : 'Next'}
          </DancingButton>
        </div>
      </div>
    </form>
  );
}

export default MessageForm;