import { useState, useEffect } from 'react';
import { messageService } from '../../firebase/messageService';
import DancingButton from '../../components/DancingButton';

function Messages() {
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [sortBy, setSortBy] = useState('date'); // 'date', 'name'

  useEffect(() => {
    loadMessages();
  }, []);

  const loadMessages = async () => {
    setIsLoading(true);
    try {
      const data = await messageService.getMessages();
      setMessages(data);
    } catch (error) {
      console.error('Error loading messages:', error);
    }
    setIsLoading(false);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchTerm.trim()) {
      loadMessages();
      return;
    }
    setIsLoading(true);
    try {
      const results = await messageService.searchMessagesByName(searchTerm.trim());
      setMessages(results);
    } catch (error) {
      console.error('Error searching messages:', error);
    }
    setIsLoading(false);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    try {
      if (timestamp.toDate) {
        return timestamp.toDate().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        });
      }
      // Handle regular Date objects or ISO strings
      return new Date(timestamp).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  const MessageCard = ({ message, isExpanded = false, onClick }) => (
    <div
      onClick={onClick}
      className={`bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-all cursor-pointer
        ${isExpanded ? 'fixed inset-4 md:inset-12 z-50 overflow-y-auto' : 'relative'}`}
    >
      {isExpanded && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            setSelectedMessage(null);
          }}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 p-2"
        >
          ✕
        </button>
      )}

      {/* Header */}
      <div className="mb-8">
        <h2 className="text-2xl md:text-3xl font-medium mb-2">
          <span className="text-periwinkle-400">Hey K</span>
          <span className="text-gray-400">, it's </span>
          <span className="text-rose-400">{message.name}</span>
        </h2>
        <p className="text-base md:text-lg text-gray-400">
          written on {formatDate(message.timestamp)}
        </p>
      </div>

      {/* Emoji Section */}
      <div className="text-center mb-8">
        <p className="text-gray-600 text-lg md:text-xl mb-3">You are the</p>
        <div className="flex items-center justify-center mb-3">
          <span className="text-6xl md:text-7xl">{message.emoji}</span>
        </div>
        <p className="text-gray-600 text-lg md:text-xl">to me</p>
      </div>

      {/* Memory Section */}
      <div className="mb-8">
        <h3 className="text-xl md:text-2xl font-medium text-periwinkle-400 mb-4">
          My favorite memory of us
        </h3>
        <div className="prose prose-lg max-w-none">
          <p className="text-gray-700 text-lg md:text-xl leading-relaxed whitespace-pre-wrap">
            {message.memory}
          </p>
        </div>
      </div>

      {/* Wish Section */}
      <div className="mb-8">
        <h3 className="text-xl md:text-2xl font-medium text-periwinkle-400 mb-4">
          My birthday wish for you
        </h3>
        <div className="prose prose-lg max-w-none">
          <p className="text-gray-700 text-lg md:text-xl leading-relaxed whitespace-pre-wrap">
            {message.wish}
          </p>
        </div>
      </div>

      {/* Image Section */}
      {message.photo && (
        <div className="mb-8">
          <h3 className="text-xl md:text-2xl font-medium text-periwinkle-400 mb-4">
            A Photo that Reminds Me of You
          </h3>
          <img
            src={message.photo} // Ensure this is the correct URL
            alt="Uploaded"
            className="w-[90%] md:w-1/2 h-full rounded-lg mx-auto" // 100% width on mobile, 50% on desktop

          />
        </div>
      )}
    </div>
  );

  const shuffleMessages = () => {
    setMessages(messages => [...messages].sort(() => Math.random() - 0.5));
  };

  const sortMessages = (criteria) => {
    setSortBy(criteria);
    const sortedMessages = [...messages].sort((a, b) => {
      if (criteria === 'date') {
        return b.timestamp?.toDate?.() - a.timestamp?.toDate?.() || 0;
      }
      if (criteria === 'name') {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });
    setMessages(sortedMessages);
  };

  return (
    <div className="min-h-screen bg-cream p-8">
      <div className="max-w-4xl mx-auto">
        {/* Search and Sort Controls */}
        <div className="mb-8 space-y-4">
          {/* Search Bar */}
          <form onSubmit={handleSearch} className="flex gap-4">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by name..."
              className="flex-1 px-4 py-2 rounded-full border border-periwinkle-200 focus:outline-none focus:ring-2 focus:ring-periwinkle-200"
            />
            <DancingButton onClick={handleSearch}>
              Search
            </DancingButton>
          </form>

          {/* Sort Controls */}
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 flex-wrap">
              <button
                onClick={shuffleMessages}
                className="px-4 py-2 bg-periwinkle-100 text-periwinkle-600 rounded-full hover:bg-periwinkle-200 transition-colors"
              >
                🎲 Shuffle
              </button>
              <button
                onClick={() => sortMessages('date')}
                className={`px-4 py-2 rounded-full transition-colors ${
                  sortBy === 'date'
                    ? 'bg-periwinkle-400 text-white'
                    : 'bg-periwinkle-100 text-periwinkle-600 hover:bg-periwinkle-200'
                }`}
              >
                📅 Latest First
              </button>
              <button
                onClick={() => sortMessages('name')}
                className={`px-4 py-2 rounded-full transition-colors ${
                  sortBy === 'name'
                    ? 'bg-periwinkle-400 text-white'
                    : 'bg-periwinkle-100 text-periwinkle-600 hover:bg-periwinkle-200'
                }`}
              >
                📝 Sort by Name
              </button>
            </div>
            <div className="flex items-center gap-4 flex-wrap">
              <span className="px-4 py-2 bg-periwinkle-50 text-periwinkle-600 rounded-full">
                💌 Total Messages: {messages.length}
              </span>
              <a 
                href="/leaderboard"
                className="px-4 py-2 bg-periwinkle-100 text-periwinkle-600 rounded-full hover:bg-periwinkle-200 transition-colors"
              >
                🏆 View Emoji Leaderboard
              </a>
            </div>
          </div>
        </div>

        {/* Messages Grid */}
        {isLoading ? (
          <div className="text-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-periwinkle-400 mx-auto"></div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {messages.map((message) => (
                <MessageCard
                  key={message.id}
                  message={message}
                  onClick={() => setSelectedMessage(message)}
                />
              ))}
            </div>

            {/* Expanded Card Modal */}
            {selectedMessage && (
              <div className="fixed inset-0 bg-black bg-opacity-50 z-40" onClick={() => setSelectedMessage(null)}>
                <MessageCard
                  message={selectedMessage}
                  isExpanded={true}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Messages;